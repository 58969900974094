import { MAX_EQUIPMENT_ITEM_NAME_LENGTH } from 'core/constants';

export const BASIC = 'basic';
export const DEFAULT_LIST_OWNER = 'blocket';

export const EQUIPMENT = 'equipments';
export const EQUIPMENT_ERROR_MESSAGE = `En utrustningspunkt måste vara mellan 1-${MAX_EQUIPMENT_ITEM_NAME_LENGTH} tecken och det är inte tillåtet att reservera sig från felskrivningar eller skriva att fordonet säljs i
befintligt skick`;
export const ERROR_MESSAGE = {
    COMMON: 'Något gick fel, försök igen senare eller kontakta kundservice. ',
    TOO_MANY_REQUESTS: 'För många försök, försök igen senare. ',
    UNAUTHORIZED: 'Du har inte behörighet att utföra denna åtgärd. '
};
export const EVENT_DRAG_START = 'dragstart';

export const KEYBOARD_ENTER = 'Enter';
export const KEYBOARD_ESCAPE = 'Escape';

export const LABEL = 'label';

export const MINIMUM_CHARGE = {
    ADMIN_PRO_CAR: '990',
    ADMIN_PRO_OTHER: '650',
    BLOCKET_CAR: '2 900',
    BLOCKET_OTHER: '1 450'
};
export const MINIMUM_CHARGE_NEW = {
    ADMIN_PRO_CAR: '1 090',
    ADMIN_PRO_OTHER: '690',
    BLOCKET_CAR: '3 200',
    BLOCKET_OTHER: '1 450'
};

export const PREMIUM = 'premium';
